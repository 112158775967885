import Alpine from 'alpinejs';

function manageResponsiveFilters() {
  return {
    isMobile: (window.innerWidth < 768),
    showFilters: (window.innerWidth > 768),

    clearFilters() {
      this.$refs.filters.querySelectorAll('input[type="checkbox"]').forEach((elem) => {
        elem.checked = false
        elem._x_model.set(false)
        this.dispatchChange(elem)
      })
    },

    dispatchChange(elem) {
      const changeEvent = new Event('change', { bubbles: true })
      elem.dispatchEvent(changeEvent)
    }
  }
}

Alpine.data('manageResponsiveFilters', manageResponsiveFilters);
