import Alpine from 'alpinejs';
import TomSelect from 'tom-select';

function initializePredictiveInput() {
  return {
    select: '',
    loadTomSelect() {
      this.select = new TomSelect(
        this.$el,
        {
          maxItems: 1,
          create: true,
          placeholder: '',
          items: [],
          createOnBlur: true,
          persist: false,
          openOnFocus: false,
          render: {
            option_create: () => '',
            no_results: () => '',
          }
        },
      );
    },
    init() {
      this.loadTomSelect()
    },
  }
}

Alpine.data('initializePredictiveInput', initializePredictiveInput);
