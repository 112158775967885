import Alpine from 'alpinejs';

function enableSubmitOnValidEmail() {
  return {
    enabled: false,

    checkEmailAndEnableSubmit() {
      const email = this.$refs.email.value

      this.enabled = this.isValidEmail(email)
    },

    isValidEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      return emailPattern.test(email);
    }
  }
}

Alpine.data('enableSubmitOnValidEmail', enableSubmitOnValidEmail);
