import { Controller } from '@hotwired/stimulus';
import { Modal } from 'tailwindcss-stimulus-components';

export default class extends Modal {
  static targets = ["newContent", "backgroundShadow"];

  newContentTargetConnected() {
    this.open()
  }

  open = () => this.withBottomUpAnimation() ? this.openSheetAnimation() : this.openFadeAnimation()
  close = () => this.withBottomUpAnimation() ? this.closeSheetAnimation() : super.close()

  openSheetAnimation() {
    super.open()
    this.containerTarget.classList.add('translate-y-full', 'transition-transform', 'transform', 'ease-in-out', 'duration-300');

    setTimeout(() => {
      this.containerTarget.classList.remove('translate-y-full')
      this.containerTarget.classList.add('translate-y-0');
    }, 0);
  }

  openFadeAnimation() {
    this.backgroundShadowTarget.classList.add('bg-black-regular/40')
    this.containerTarget.classList.add('fadeIn')
    super.open()
  }

  closeSheetAnimation() {
    this.containerTarget.classList.add('transition-transform', 'transform', 'ease-in-out', 'duration-300');

    setTimeout(() => {
      this.containerTarget.classList.add('translate-y-full')
      this.containerTarget.classList.remove('translate-y-0')
    }, 10);

    setTimeout(() => {
      super.close()
    }, 1000)
  }

  withBottomUpAnimation() {
    const animations = this.containerTarget.querySelectorAll("[data-bottom-up-animation]")
    return (animations.length != 0 && window.innerWidth < 768)
  }
}
