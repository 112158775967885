import Alpine from 'alpinejs';

function filterNumberAndClear(select) {
  return {
    open: false,
    labelNumber: 0,

    countSelectedOptions() {
      selectedRadio = this.$el.querySelectorAll('input[type=radio]:checked')[0]?.value

      switch (selectedRadio) {
        case 'state':
          return this.getCheckedOptionsCount('state_select');
        case 'county':
          return this.getCheckedOptionsCount('county_select');
        case 'city':
          return this.getCheckedOptionsCount('city_select');
        case 'postal_code':
          return this.isPostalCodeFilled() ? 1 : 0;
        default:
          return 0;
      }
    },

    countChecked() { this.labelNumber = this.$el.querySelectorAll('input[type=checkbox]:checked').length },

    updateLabelNumber() {
      this.labelNumber = select ? this.countSelectedOptions() : this.countCheckedCheckboxes();
    },

    getCheckedOptionsCount(selectId) {
      return document.getElementById(selectId).querySelectorAll('option:checked').length;
    },

    countCheckedCheckboxes() {
      return this.$el.querySelectorAll('input[type=checkbox]:checked').length;
    },

    isPostalCodeFilled() {
      return document.getElementById('zip_code_input').value !== '';
    },

    init() {
      this.$nextTick(() => this.updateLabelNumber())
    },

    clearPostalCode() {
      this.$refs.postalCodeInput.value = '';
      this.$dispatch('change')
    },

    clearCheckboxes() {
      this.$refs.container.querySelectorAll('input[type="checkbox"]').forEach((elem) => this.clearCheckbox(elem))
      this.$dispatch('change')
    },

    clearCheckbox(elem) {
      elem.checked = false
      elem._x_model.set(false)
    }
  }
}


Alpine.data('filterNumberAndClear', filterNumberAndClear);
