import Alpine from "alpinejs";

function selectTip() {
  return {
    tip: "",
    setTip(value) {
      this.tip = this.tip === value ? "" : value
      document.getElementById("tips_for_cm_input").value = this.tip;
    }
  }
}

Alpine.data("selectTip", selectTip);
