import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  connect() {
    const onOpen = (dropdown) => {
      let bounding = dropdown.getBoundingClientRect();
      if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
          dropdown.classList.add('dropup');
      }
    }
    const onClose = (dropdown) => {
      dropdown.classList.remove('dropup');
    }
    this.select = new TomSelect(
      this.element.querySelectorAll('select')[0],
      {
        maxItems: this.maxItems(this.element),
        items: this.selectedItem(this.element),
        plugins: {
          remove_button: {
            title: 'Remove this item',
            className: "text-white font-semibold px-2"
          }
        },
        render: {
          item: this.renderItem,
        },
      },
    );
    this.select.on('dropdown_open', onOpen);
    this.select.on('dropdown_close', onClose);
  }

  disconnect() {
    this.select.destroy();
  }

  renderItem = (data, escape) => (`
    <div class="!bg-primary-8 rounded-full !px-2 !py-1">
      <p class="text-white font-semibold">
        ${escape(data.text)} 
      </p>
    <div>
  `)

  maxItems(element) {
    if (element.hasAttribute('data-items') && element.dataset.items == 'multiselect') {
      return null;
    }
    return 1;
  }

  clear() {
    this.select.clear()
  }

  selectedItem(element) {
    if (element.hasAttribute('selectedValue')) {
      return element.attributes.selectedValue.value;
    }
    if (element.hasAttribute('selectedValues')) {
      return element.attributes.selectedValues.value.split('_');
    }
    return null;
  }
}
